import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { share } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AuthService } from "src/app/pages/auth/auth.service";
import { LayoutService } from "../services/layout.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    // private loader: LoaderComponent,
    private toastrService: ToastrService,
    private router: Router,
    private loaderService: LayoutService,
    private service: AuthService
  ) {}

  isLoginPage(): boolean {
    return this.router.url === "/auth";
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const noLoader = request.params.get("noLoader") ? request.params.get("noLoader") : false;
    const token: any = localStorage.getItem("token") // Recupera il token di autenticazione
      ? localStorage.getItem("token")
      : "";
    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${token}`),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    // Show loader
    if (!noLoader) {
      this.loaderService.show();
    }

    return this.makeRequest(request, next);
  }

  makeRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req: Observable<HttpEvent<any>> = next.handle(request).pipe(
      share(),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (request.method !== "GET") {
            if (event.body && event.body.success) {
              this.toastrService.success(event.body.message, "", {
                positionClass: "toast-bottom-right",
                tapToDismiss: true,
              });
            } else if (event.body && !event.body.success) {
              this.toastrService.error(event.body.message, "Attenzione!", {
                positionClass: "toast-bottom-right",
                tapToDismiss: true,
              });
            }
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.refreshToken(err);
        } else if (err.status === 422) {
          if (err && err.error) {
            const errorMessages = Array.isArray(err.error.errors)
              ? "<ul>" + err.error.errors.map((e: string) => `<li>${e}</li>`).join("") + "</ul>"
              : err.error.message;
            this.toastrService.error(errorMessages, err.error.message, {
              positionClass: "toast-bottom-right",
              tapToDismiss: true,
              enableHtml: true,
            });
          }
        } else {
          // Generic error
          if (err.status == null || err.status == undefined || err.status == 0 || !navigator.onLine) {
            this.toastrService.error("Connessione internet assente", "Attenzione", {
              positionClass: "toast-bottom-right",
              tapToDismiss: true,
              enableHtml: true,
            });
          } else {
            this.toastrService.error(err.error?.message, "Si è verificato un problema", {
              positionClass: "toast-bottom-right",
            });
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        // Hide loader
        this.loaderService.hide();
      })
    );

    return req;
  }

  refreshToken(err: any) {
    const token = localStorage.getItem("token");
    const rememberMe = localStorage.getItem("rememberMe");
    if (token && rememberMe === "true") {
      this.service.refreshToken(token).subscribe({
        next: (res: any) => {
          localStorage.setItem("token", res.data.token);
          window.location.reload();
        },
        error: (err) => {
          this.sessionExpired(err);
        },
      });
    } else {
      this.sessionExpired(err);
    }
  }

  sessionExpired(err: any) {
    localStorage.clear();
    if (err instanceof HttpErrorResponse) {
      try {
        if (!this.isLoginPage()) {
          this.toastrService.error("Sessione scaduta", err.statusText, {
            positionClass: "toast-bottom-right",
          });
          setTimeout(() => (window.location.href = window.location.origin + "/auth"), 3000);
        }
      } catch (e) {
        this.toastrService.error("Errore", "", {
          positionClass: "toast-bottom-right",
        });
        setTimeout(() => (window.location.href = window.location.origin + "/auth"), 3000);
      }
    }
  }
}
