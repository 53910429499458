import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MinMaxCheckService {
  constructor() {}

  enforceMinMax(event: any) {
    const el = event.target;
    if (el.value != "") {
      if (parseInt(el.value) <= parseInt(el.min)) {
        el.value = el.min;
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
  }
}
