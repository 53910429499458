import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BaseComponent } from "../base-component/base-component.component";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  debounceTime,
  distinctUntilChanged,
  isEmpty,
  Observable,
  Subscription,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "src/environments/environment";
import { AttivitaService } from "src/app/pages/attività/attivita.service";

@Component({
  selector: "app-dynamic-input",
  templateUrl: "./dynamic-input.component.html",
  styleUrl: "./dynamic-input.component.scss",
})
export class DynamicInputComponent extends BaseComponent {
  private subscriptions: Subscription[] = [];
  @Input() inputData: any;
  @Input() userRole: any;
  @Input() userId: any;
  @Input() mode: "nuovo" | "modifica" | "visualizza" = "visualizza";
  @Input() isSaving: any;
  @Output() valueEmitter = new EventEmitter<any>();
  @Output() changeSave = new EventEmitter<any>();
  protected formattedDate: NgbDateStruct;
  protected searchTerm = "";
  protected apiUrl = "";
  protected data: any;
  protected isInvalid: boolean = false;
  protected multiRowData: any[] = [];
  protected bindedLabel: string = "description";

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private attivitaService: AttivitaService
  ) {
    super();
  }

  override ngOnInit(): void {
    if (this.inputData?.inputType === "date") {
      const dateValue = this.inputData?.value;

      if (dateValue && dateValue !== "") {
        const date = this.dateFormatService.isoStringToNgbDate(dateValue);
        this.formattedDate = this.dateFormatService.formatDate(date, true);
      } else {
        const today = new Date();
        this.formattedDate = this.dateFormatService.formatDate(
          new NgbDate(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDate()
          ),
          true
        );
        this.inputData.value = this.formattedDate;
      }
    }

    if (this.inputData?.inputType === "checkbox") {
      if (
        this.inputData?.value !== "" &&
        this.inputData?.value !== null &&
        this.inputData?.value !== undefined
      ) {
        this.inputData.value =
          this.inputData?.value == true || this.inputData?.value == "True"
            ? true
            : false;
      } else {
        this.inputData.value =
          this.inputData?.defaultValue == true ||
          this.inputData?.defaultValue == "true"
            ? true
            : false;
      }
    }

    if (this.inputData?.inputType === "select") {
      if (this.inputData?.url) {
        if (!this.inputData?.data) {
          this.apiUrl = this.inputData?.url;
          this.selectByUrl();
        }

        if (this.inputData?.multiRow && this.mode === "nuovo") {
          this.multiRowData.push({
            name: this.inputData?.name,
            value: "",
            mandatory: this.inputData?.mandatory ? true : false,
            multiSelect: this.inputData?.multiSelect,
            inputType: this.inputData?.inputType,
            dataType: this.inputData?.dataType,
            quantity: 0,
          });
        } else if (this.inputData?.multiRow && this.mode !== "nuovo") {
          this.inputData.value.forEach((element: any) => {
            this.multiRowData.push({
              name: this.inputData?.name,
              value: element?.idProduct,
              mandatory: this.inputData?.mandatory ? true : false,
              multiSelect: this.inputData?.multiSelect,
              inputType: this.inputData?.inputType,
              dataType: this.inputData?.dataType,
              quantity: element?.quantity,
            });
          });
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.inputData?.parent === "Medico Visitato") {
      this.subscriptions.push(
        this.attivitaService.$addressSubject.subscribe((res) => {
          if (res) {
            if (this.mode === "nuovo") {
              this.inputData.value = null;
            }
            this.data = res.addresses;
            this.checkBindedValue();
          }
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isSaving"] && changes["isSaving"]?.currentValue) {
      const value = this.inputData?.value;
      const isValid =
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (Array.isArray(value) ? value.length > 0 : true);

      if (this.inputData?.mandatory && !isValid) {
        this.isInvalid = true;
      } else {
        if (this.inputData?.inputType === "date") {
          this.inputData.value = this.dateFormatService.transformStringDate(
            this.inputData?.value
          );
        }
        this.valueEmitter.emit({
          inputData: this.inputData,
          multiRowData: this.multiRowData,
        });
        this.isInvalid = false;
        this.changeSave.emit();
        this.cdr.detectChanges();
      }
    }
  }

  onInputChange(event: any, inputType?: string, index?: number): void {
    const value = event?.target?.value ?? event;

    switch (inputType) {
      case "checkbox":
        this.inputData.value = event.target.checked;
        break;
      case "select":
        this.inputData.value = value;
        this.isInvalid = Array.isArray(value) ? value.length === 0 : !value;
        if (this.inputData?.multiRow && index !== undefined) {
          this.multiRowData[index].value =
            value !== null && value !== undefined && value !== ""
              ? value
              : null;
        }
        if (this.inputData?.name === "Medico Visitato") {
          this.onDoctorChange(value);
        }
        break;
      default:
        if (!isNaN(value) && this.inputData?.dataType === "Int") {
          this.inputData.value = +value;
          this.isInvalid = !value;
        } else {
          this.inputData.value = value ?? "";
          this.isInvalid = !value;
        }
    }
  }

  onDoctorChange(doctorId: number) {
    const selectedAddresses = this.data.filter(
      (doctor: any) => doctor.id == doctorId
    )[0]?.addresses;
    this.attivitaService.$addressSubject.next({
      id: doctorId,
      addresses: selectedAddresses,
    });
  }

  onSearch(event: any) {
    this.searchTerm = event.term;
    const searchTerm$ = new Observable<string>((observer) => {
      observer.next(event.term);
      observer.complete();
    })
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (event.term.length > 3 || event.term.length === 0) {
          this.selectByUrl();
        }
      });
  }

  addRow() {
    this.multiRowData.push({
      name: this.inputData?.name,
      value: "",
      mandatory: this.inputData?.mandatory,
      multiSelect: this.inputData?.multiSelect,
      inputType: this.inputData?.inputType,
      dataType: this.inputData?.dataType,
      quantity: 0,
    });
  }

  removeRow(index: number) {
    this.multiRowData.splice(index, 1);
  }

  selectByUrl() {
    const url = new URL(env.baseUrl + this.apiUrl);
    if (
      (this.userRole?.name === "ISF" || this.userRole?.name === "SP") &&
      this.inputData?.name === "Medico Visitato"
    ) {
      url.searchParams.set(this.userRole.name.toLowerCase(), this.userId);
    }
    if (this.searchTerm) {
      url.searchParams.set("name", this.searchTerm);
    }
    return this.http.get<any>(url.toString()).subscribe({
      next: (res) => {
        this.data = res.data?.list ? res.data?.list : res.data;
        if (this.inputData?.name === "Medico Visitato") {
          this.onInputChange(this.inputData?.value, "select");
        }
        this.checkBindedValue();
      },
    });
  }

  checkBindedValue() {
    switch (true) {
      case this.data && this.data[0]?.hasOwnProperty("descriptionProvince"):
        this.bindedLabel = "descriptionProvince";
        break;
      case this.data && this.data[0]?.hasOwnProperty("businessName"):
        this.bindedLabel = "businessName";
        break;
      case this.data && this.data[0]?.hasOwnProperty("address"):
        this.bindedLabel = "address";
        break;
      case this.data && this.data[0]?.hasOwnProperty("fullName"):
        this.bindedLabel = "fullName";
        break;
      case this.data && this.data[0]?.hasOwnProperty("surname"):
        this.data = this.data.map((item: any) => {
          return {
            ...item,
            fullName: item.name + " " + item.surname,
          };
        });
        this.bindedLabel = "fullName";
        break;
      default:
        this.bindedLabel = "name";
    }
  }

  onDateChange(date: NgbDate) {
    this.formattedDate = this.dateFormatService.formatDate(date, true);
    this.inputData.value = this.formattedDate;
    this.isInvalid = false;
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
