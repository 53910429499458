import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class FormatDateService {
  constructor() {}

  isoStringToNgbDate(isoString: string): NgbDateStruct {
    const dateSplit = isoString.split("-");
    let day, month, year;
    if (dateSplit[0].length === 4) {
      year = parseInt(dateSplit[0], 10);
      month = parseInt(dateSplit[1], 10);
      day = parseInt(dateSplit[2], 10);
    } else {
      day = parseInt(dateSplit[0], 10);
      month = parseInt(dateSplit[1], 10);
      year = parseInt(dateSplit[2], 10);
    }
    return {
      day,
      month,
      year,
    };
  }

  formatDate(date: NgbDateStruct, it?: boolean): any {
    if (!date) {
      return null;
    }
    const year = date.year;
    const month = String(date.month).padStart(2, "0");
    const day = String(date.day).padStart(2, "0");
    if (!it) return `${year}-${month}-${day}`;
    else return `${day}-${month}-${year}`;
  }

  transformStringDate(dateString: string): string {
    if (!dateString) return "";
    const [part1, part2, part3] = dateString.split("-");
    const isYearFourDigits = part1.length === 4;
    const year = isYearFourDigits ? part1 : part3;
    const month = part2;
    const day = isYearFourDigits ? part3 : part1;
    return `${year}-${month}-${day}`;
  }
}
