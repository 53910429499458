import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, ErrorHandler, Injectable, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { CommonServiceService } from "../../services/common-service.service";
import { NavService } from "../../services/nav.service";
import { ToastrService } from "ngx-toastr";
import { MinMaxCheckService } from "../../services/min-max-check.service";
import { FormatDateService } from "../../services/format-date.service";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-base-component",
  templateUrl: "./base-component.component.html",
  styleUrl: "./base-component.component.scss",
})
export class BaseComponent implements OnInit, OnDestroy {
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected navService: NavService = inject(NavService);
  protected minMaxService: MinMaxCheckService = inject(MinMaxCheckService);
  protected dateFormatService: FormatDateService = inject(FormatDateService);
  protected toastr: ToastrService = inject(ToastrService);
  protected errorHandler: ErrorHandler = inject(ErrorHandler);
  protected router: Router = inject(Router);
  protected location: Location = inject(Location);
  protected commonService: CommonServiceService = inject(CommonServiceService);
  protected Swal = Swal;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}

export class BaseComponentHeader extends BaseComponent {
  constructor() {
    super();
  }
}
export class BaseComponentFooter extends BaseComponent {
  constructor() {
    super();
  }
}
