<div>
  <!-- input Base  -->
  <div *ngIf="inputData?.inputType === 'input'" class="form-group">
    <label class="form-label" [for]="inputData.name">
      {{ inputData.name }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <input
      (input)="onInputChange($event)"
      class="form-control"
      [id]="inputData?.id"
      [value]="inputData?.value ? inputData?.value : ''"
      [type]="inputData?.dataType == 'Int' ? 'number' : 'text'"
      [disabled]="mode === 'visualizza'"
    />
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input TextArea  -->
  <div *ngIf="inputData?.inputType === 'textarea'" class="form-group">
    <label class="form-label" [for]="inputData.name">
      {{ inputData.name }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <textarea
      (input)="onInputChange($event)"
      class="form-control"
      rows="3"
      [value]="inputData?.value ? inputData?.value : ''"
      [disabled]="mode === 'visualizza'"
    ></textarea>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- Input MultiRow -->
  <div
    *ngIf="
      inputData?.inputType === 'select' &&
      inputData?.multiRow &&
      multiRowData.length > 0
    "
  >
    <div
      *ngFor="let item of multiRowData; index as i"
      class="form-group d-flex align-items-center justify-content-between gap-2"
      [ngClass]="{ 'mb-3': i !== multiRowData.length - 1 }"
    >
      <div style="width: 60%">
        <label class="form-label" [for]="item?.name">
          {{ item?.name }}
          <sup *ngIf="item?.mandatory && mode !== 'visualizza'">*</sup>
        </label>
        <ng-select
          [items]="data ? data : inputData?.data"
          [searchable]="true"
          [ngModel]="item?.value"
          [multiple]="item?.multiSelect"
          [closeOnSelect]="item?.multiSelect ? false : true"
          [bindLabel]="bindedLabel"
          bindValue="id"
          class="selectResearch"
          [ngClass]="{ 'single-control': !item?.multiSelect }"
          (search)="onSearch($event)"
          (ngModelChange)="onInputChange($event, 'select', i)"
          [disabled]="mode === 'visualizza'"
        ></ng-select>
        <div class="text-danger" *ngIf="item?.mandatory && isInvalid">
          <div>Campo obbligatorio</div>
        </div>
      </div>
      <div style="width: 30%">
        <label class="form-label" [for]="'quantità'">Quantità</label>
        <div class="d-flex align-items-center justify-content-between gap-1">
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="item.quantity = item.quantity > 0 ? item.quantity - 1 : ''"
          >
            <img
              style="height: auto; width: 10px; filter: invert()"
              src="../../../../assets/svg/minus-icon.svg"
              alt="Meno"
            />
          </button>
          <input
            (input)="onInputChange($event)"
            class="form-control"
            [id]="item?.id"
            [value]="item?.quantity ? item?.quantity : 0"
            [type]="item?.dataType == 'Int' ? 'number' : 'text'"
            [disabled]="mode === 'visualizza'"
          />
          <button
            *ngIf="mode !== 'visualizza'"
            class="btn small-square-primary button-add-object"
            type="button"
            (click)="item.quantity = item.quantity + 1"
          >
            <svg
              fill="#ffffff"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="64px"
              height="64px"
              viewBox="-9.08 -9.08 63.56 63.56"
              xml:space="preserve"
              stroke="#ffffff"
              stroke-width="0.00045402"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke="#CCCCCC"
                stroke-width="0.18160800000000002"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141 c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27 c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435 c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div style="width: 10%" class="d-flex gap-1">
        <button
          *ngIf="i > 0 && mode !== 'visualizza'"
          class="btn px-0 btn-small-plus"
          type="button"
          (click)="removeRow(i)"
        >
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 1024 1024"
            fill="#E75553"
            class="icon pointer delete-row-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M32 241.6c-11.2 0-20-8.8-20-20s8.8-20 20-20l940 1.6c11.2 0 20 8.8 20 20s-8.8 20-20 20L32 241.6zM186.4 282.4c0-11.2 8.8-20 20-20s20 8.8 20 20v688.8l585.6-6.4V289.6c0-11.2 8.8-20 20-20s20 8.8 20 20v716.8l-666.4 7.2V282.4z"
                fill=""
              ></path>
              <path
                d="M682.4 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM367.2 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM524.8 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM655.2 213.6v-48.8c0-17.6-14.4-32-32-32H418.4c-18.4 0-32 14.4-32 32.8V208h-40v-42.4c0-40 32.8-72.8 72.8-72.8H624c40 0 72.8 32.8 72.8 72.8v48.8h-41.6z"
                fill=""
              ></path>
            </g>
          </svg>
        </button>
        <button
          *ngIf="multiRowData.length === i + 1 && mode !== 'visualizza'"
          class="btn px-0 btn-small-plus"
          type="button"
          (click)="addRow()"
        >
          <img
            src="../../../../assets/svg/small-plus-icon.svg"
            alt="Aggiungi"
          />
        </button>
      </div>
    </div>
  </div>

  <!-- input Select  -->
  <div
    *ngIf="inputData?.inputType === 'select' && !inputData?.multiRow"
    class="form-group"
  >
    <label class="form-label" [for]="inputData.name">
      {{ inputData.name }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <ng-select
      [items]="data ? data : inputData?.data"
      [searchable]="true"
      [ngModel]="inputData?.value"
      [multiple]="inputData?.multiSelect"
      [closeOnSelect]="inputData?.multiSelect ? false : true"
      [bindLabel]="bindedLabel"
      bindValue="id"
      class="selectResearch"
      [ngClass]="{ 'single-control': !inputData?.multiSelect }"
      (search)="onSearch($event)"
      (ngModelChange)="onInputChange($event, 'select')"
      [disabled]="mode === 'visualizza'"
    ></ng-select>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input Data  -->
  <div *ngIf="inputData?.inputType === 'date'" class="form-group">
    <label class="form-label" [for]="inputData.name">
      {{ inputData.name }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <input
      class="datepicker-here form-control digits"
      id="startDate"
      placeholder="gg-mm-aaaa"
      [value]="formattedDate"
      name="dp"
      ngbDatepicker
      #d="ngbDatepicker"
      (click)="d.toggle()"
      (dateSelect)="onDateChange($event)"
      (input)="onInputChange($event, 'date')"
      [disabled]="mode === 'visualizza'"
    />
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>

  <!-- input CheckBox  -->
  <div *ngIf="inputData?.inputType === 'checkbox'" class="form-group">
    <label class="form-label" [for]="inputData.name">
      {{ inputData.name }}
      <sup *ngIf="inputData?.mandatory && mode !== 'visualizza'">*</sup>
    </label>
    <div class="checkbox m-squar">
      <input
        [id]="inputData?.id"
        type="checkbox"
        [checked]="inputData?.value ? true : false"
        [value]="inputData?.value ? true : false"
        (change)="onInputChange($event, 'checkbox')"
        [disabled]="mode === 'visualizza'"
      />
      <span class="checkmark"></span>
    </div>
    <div class="text-danger" *ngIf="inputData?.mandatory && isInvalid">
      <div>Campo obbligatorio</div>
    </div>
  </div>
</div>
